import React, { useEffect, useState } from "react";
import { Button, Container, Image, Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import ImageGallery from "react-image-gallery";
import axios from "axios";

function Header() {
  const [type, setType] = useState("");
  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  const images = [
    {
      original: require("../images/gallery/gallery-1.jpg"),
      thumbnail: require("../images/gallery/gallery-1.jpg"),
      originalWidth: "500px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "200px",
    },
    {
      original: require("../images/gallery/gallery-2.jpg"),
      thumbnail: require("../images/gallery/gallery-2.jpg"),
      originalWidth: "500px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "200px",
    },
    {
      original: require("../images/gallery/gallery-3.jpg"),
      thumbnail: require("../images/gallery/gallery-3.jpg"),
      originalWidth: "500px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "200px",
    },
    {
      original: require("../images/gallery/gallery-4.jpg"),
      thumbnail: require("../images/gallery/gallery-4.jpg"),
      originalWidth: "500px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "200px",
    },
    {
      original: require("../images/gallery/gallery-5.jpg"),
      thumbnail: require("../images/gallery/gallery-5.jpg"),
      originalWidth: "500px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "200px",
    },
    {
      original: require("../images/gallery/gallery-6.jpg"),
      thumbnail: require("../images/gallery/gallery-6.jpg"),
      originalWidth: "500px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "200px",
    },
    {
      original: require("../images/gallery/gallery-7.jpg"),
      thumbnail: require("../images/gallery/gallery-7.jpg"),
    },
  ];

  const getWhatsApp = () => {
    return window.open(
      `https://wa.me/+6282191156677?text=Saya%20ingin%20menjadi%20agen%20hewan%20kurban%20ternakita,%20mohon%20bantuannya.`,
      "_blank",
      "rel=noopener noreferrer"
    );
  };

  const getWhatsAppRoutate = async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_API_URL}${"wa-rotator"}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      );

      const whatsappMessage = `Saya ingin membeli hewan kurban ternakita, mohon bantuannya.`;
      const whatsappURL = `https://wa.me/${
        response.data.data.phone
      }?text=${encodeURIComponent(whatsappMessage)}`;

      window.open(whatsappURL, "_blank", "rel=noopener noreferrer");
    } catch (error) {
      console.error("Error fetching WhatsApp number:", error);
    }
  };

  useEffect(() => {
    if (isMobileDevice()) {
      setType(true);
    } else {
      setType(false);
    }
  }, []);

  return (
    <div className="header position-relative">
      {/* <Container className="text-center"> */}
      {/* {!type ? (
        <Container className="text-center"></Container>
      ) : (
        <> */}
      <Image
        src={require("../images/mobile/lp-promo-mobile-1.jpg")}
        className="img-mobile"
        style={{ width: "100%" }}
      />
      <Image
        src={require("../images/mobile/lp-promo-mobile-2.jpg")}
        className="img-mobile"
        style={{ width: "100%" }}
      />
      <Col className="p-3" style={{ backgroundColor: "#E9E9E9" }}>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/aeo163HsFeM?si=XBsYXHSLwi4wQKO5"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </Col>
      <Image
        src={require("../images/mobile/lp-promo-mobile-4.jpg")}
        className="img-mobile"
        style={{ width: "100%" }}
      />
      <Col className="p-3 text-center" style={{ backgroundColor: "#ffff" }}>
        <ImageGallery items={images} />
      </Col>
      <Col
        className="d-flex justify-content-center p-3"
        style={{ position: "", bottom: 0, width: "100%" }}
      >
        <button
          class="btn-agen"
          role="button"
          onClick={() => getWhatsAppRoutate()}
        >
          <FontAwesomeIcon icon={faPhone} /> Saya Ingin Berqurban !!
        </button>
      </Col>
      <Image
        src={require("../images/mobile/lp-promo-mobile-5.jpg")}
        className="img-mobile"
        style={{ width: "100%" }}
      />
      <Col
        className="d-flex justify-content-center p-3"
        style={{ position: "", bottom: 0, width: "100%" }}
      >
        <button class="btn-agen" role="button" onClick={() => getWhatsApp()}>
          <FontAwesomeIcon icon={faPhone} /> Saya Ingin Menjadi Agen !!
        </button>
      </Col>
      <Image
        src={require("../images/mobile/lp-promo-mobile-7.png")}
        className="img-mobile"
        style={{ width: "100%" }}
      />
    </div>
  );
}

export default Header;
